import { makeActionCreator } from '../../config/store/utils'
import { onFetchDataTrucks, onFetchDataTrucksSuccess, onFetchDataTrucksError } from '../FuelLevel/FuelLevel.actions'
import { batch } from 'react-redux'
import { reloadDone } from '../../store/actions/screenActions'

export const SET_MISSING_TIME_SUCCESS = 'SET_MISSING_TIME_SUCCESS'
export const onSetMissingTimeSuccess = makeActionCreator(SET_MISSING_TIME_SUCCESS, 'payload')

export const onWsDataTrucksThunk = wsTrucks => async dispatch => {
  dispatch(onFetchDataTrucks())

  try {
    const trucks = wsTrucks
    const lowLevelGas = wsTrucks.filter(truck => truck.percent < 20)
    const posibleError = lowLevelGas.length > 300

    return batch(() => {
      dispatch(
        onFetchDataTrucksSuccess({
          trucks,
          posibleError
        })
      )
      dispatch(reloadDone())
    })
  } catch (error) {
    return dispatch(onFetchDataTrucksError({ error }))
  }
}

export const GET_ORPAK_LATEST = 'GET_ORPAK_LATEST'
export const GET_ORPAK_LATEST_ERROR = 'GET_ORPAK_LATEST_ERROR'
export const GET_ORPAK_LATEST_SUCCESS = 'GET_ORPAK_LATEST_SUCCESS'
export const onGetOrpakLatest = makeActionCreator(GET_ORPAK_LATEST)
export const onGetOrpakLatestError = makeActionCreator(GET_ORPAK_LATEST_ERROR, 'payload')
export const onGetOrpakLatestSuccess = makeActionCreator(GET_ORPAK_LATEST_SUCCESS, 'payload')
export const onGetOrpakLatestThunk = (orpak, tankLevelsMean, weeklyTankMean, dayTurn, nightTurn) => async (
  dispatch,
  getState
) => {
  dispatch(onGetOrpakLatest())

  try {
    const orpakData = orpak.map(item => {
      const {
        equipo: camion,
        Fecha: fecha,
        FInicio: fechaInicio,
        FFin: fechaFin,
        cantidad: cantidadAbastecida,
        Capacidad: capacidad,
        Diff: diferencia
      } = item
      const cumplimiento = ((diferencia / capacidad) * 100).toFixed(2)
      const limSup = Math.round(capacidad * 0.8)
      const limInf = Math.round(capacidad * 0.7)
      const hora = fechaInicio.split(':')[0]
      let turno = ''
      if ((hora > 8) & (hora < 20)) {
        turno = 'A'
      } else {
        turno = 'B'
      }
      return {
        camion,
        fecha: new Date(fecha),
        fechaInicio,
        turno,
        fechaFin,
        cantidadAbastecida,
        capacidad,
        diferencia,
        cumplimiento,
        limSup,
        limInf
      }
    })

    const levelsMean = tankLevelsMean
    const weeklyTank = weeklyTankMean
    return batch(() => {
      dispatch(
        onGetOrpakLatestSuccess({
          orpakData: {
            data: orpakData
          },
          levelsMean: levelsMean,
          weeklyTank: weeklyTank,
          dayTurn: dayTurn,
          nightTurn: nightTurn
        })
      )
    })
  } catch (error) {
    console.log(error)
    return dispatch(onGetOrpakLatestError({ error }))
  }
}
