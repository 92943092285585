import {
  SET_MISSING_TIME_SUCCESS,
  GET_ORPAK_LATEST,
  GET_ORPAK_LATEST_ERROR,
  GET_ORPAK_LATEST_SUCCESS
} from './Countdown.actions'

const countdownState = {
  missingTime: null,
  orpakLoading: false,
  orpakError: false,
  orpakData: {
    data: []
  },
  levelsMean: 0,
  weeklyTank: 0,
  dayTurn: 0,
  nightTurn: 0
}

/**
 * The countdown' reducer.
 */
const countdownReducer = (state = countdownState, { payload, type }) => {
  switch (type) {
    case SET_MISSING_TIME_SUCCESS: {
      return { ...state, missingTime: payload.missingTime }
    }
    case GET_ORPAK_LATEST: {
      return { ...state, orpakLoading: true, orpakError: false }
    }
    case GET_ORPAK_LATEST_ERROR: {
      return { ...state, orpakLoading: false, orpakError: true }
    }
    case GET_ORPAK_LATEST_SUCCESS: {
      return {
        ...state,
        orpakLoading: false,
        orpakError: false,
        orpakData: payload.orpakData,
        levelsMean: payload.levelsMean,
        weeklyTank: payload.weeklyTank,
        dayTurn: payload.dayTurn,
        nightTurn: payload.nightTurn
      }
    }
    default: {
      return state
    }
  }
}

export default countdownReducer
